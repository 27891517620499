<template>
  <v-dialog
    v-model="bolgelerDurum"
    :transition="
      $vuetify.breakpoint.mdAndDown
        ? 'slide-y-reverse-transition'
        : 'fade-transition'
    "
    max-width="400"
    scrollable
  >
    <template>
      <v-card class="transparent">
        <v-card-title class=" white pb-0 pl-6 pr-6">
          {{ $t("teslimatSekli") }}
          <v-spacer />
          <v-btn fab small elevation="0" @click="bolgelerDurum = false">
            <v-icon size="21">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="white pb-9">
          <v-radio-group v-model="teslimatSekli">
            <v-radio
              class="pb-3"
              color="black"
              :label="$t('mekandaTeslim')"
              :value="1"
            />
            <v-radio color="black" :label="$t('adresteTeslim')" :value="2" />
          </v-radio-group>
          <v-select
            v-if="teslimatSekli == 2"
            v-model="bolge"
            class="pt-3"
            color="black"
            :items="bolgelerSorgula"
            :menu-props="{ offsetY: true, maxHeight: 600 }"
            item-text="ad"
            hide-details
            prepend-inner-icon="mdi-map"
            outlined=""
            :label="$t('bolgeSecimi')"
            item-value="object_key"
            @change="bolgeSec()"
          />
        </v-card-text>
        <v-card-actions v-if="teslimatSekli == 1" class="white pb-3 pr-3 mt-n9">
          <v-spacer />
          <v-btn
            depressed
            class="text-capitalize"
            color="primary"
            @click="siteyeGit"
          >
            {{ $t("devam") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import store from "./../store/index";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Bolgeler",
  computed: {
    ...sync("app", [
      "bolgelerDurum",
      "bolgeler",
      "teslimatTip",
      "sepetYukseklik",
      "alisverisDurum"
    ]),
    bolgelerSorgula() {
      var filters = _.filter(this.bolgeler[0], { durum: true });
      var order = _.orderBy(
        filters,
        [filter => filter.ad.toLowerCase()],
        ["asc"]
      );

      return order;
    }
  },
  data() {
    return {
      teslimat: false,
      bolge: "",
      teslimatSekli: 2
    };
  },
  created() {
    if (this.$cookies.get("cerezBolge")) {
      this.bolge = this.$cookies.get("cerezBolge");
    }
  },
  watch: {
    bolgelerDurum(val) {
      if (!this.bolge) {
        this.$store.set("app/bolge", {});
        this.$store.set("app/getirUcret", 0);
        this.$store.set("app/enAzSipasir", 0);
        this.$store.set("app/teslimatTip", 0);
        this.$store.set("app/sepetYukseklik", 0);
        this.$store.set("app/alisverisDurum", true);
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 100);
      }
    }
  },
  methods: {
    bolgeSec() {
      const veri = _.find(this.bolgeler[0], {
        object_key: this.bolge
      });
      if (_.isObject(veri)) {
        if (this.$cookies.get("cerezKabul")) {
          this.$cookies.set("cerezBolge", this.bolge);
          this.$cookies.set("cerezGetirUcret", veri.kargoUcreti);
          this.$cookies.set("cerezEnAzSipasir", veri.minSiparis);
        }
        this.$store.set("app/getirUcret", veri.kargoUcreti);
        this.$store.set("app/enAzSipasir", veri.minSiparis);
        this.$store.set("app/bolgelerDurum", false);
        this.$store.set("app/teslimatTip", 1);
        this.$store.set("app/bolge", this.bolge);
        this.$store.set("app/sepetYukseklik", 160);
      }
    },
    siteyeGit() {
      this.$store.set("app/bolge", {});
      this.$store.set("app/sepetYukseklik", 0);
      this.$store.set("app/bolgelerDurum", false);
      this.$store.set("app/getirUcret", 0);
      this.$store.set("app/enAzSipasir", 0);
      this.$store.set("app/teslimatTip", 0);
    }
  }
};
</script>

<style lang="css" scoped></style>
