<template>
  <div class="">
    <v-sheet
      v-if="cartUrunler.length == 0"
      rounded="lg"
      class="pt-10 pb-7 text-center"
      color=""
    >
      <v-icon left size="120" color="grey lighten-2">
        mdi-cart-off
      </v-icon>
      <div class="">
        <v-chip small class="px-6 mt-9 text-caption" color="primary">
          {{ $t("sepetBos") }}
        </v-chip>
      </div>
    </v-sheet>
    <v-card v-else class="overflow-hidden ">
      <v-toolbar dense flat height="75">
        <v-icon class="ml-1 mr-4">
          mdi-cart
        </v-icon>
        <v-toolbar-title>
          <div class="" style="line-height:1">
            {{ $t("sepetiniz") }}
          </div>
          <div class="text-caption text--disabled">
            {{ $t("sepetUrunSayi", { miktar: cartMiktar }) }}
          </div>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="sepetGoruntule()">
          <v-icon small>
            mdi-arrow-top-right-bottom-left
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-list
        :max-height="menuYukseklikHesaplama"
        color="grey lighten-5"
        class="overflow-y-auto py-0"
      >
        <div v-for="(urun, i) in cartUrunler">
          <v-list-item three-line>
            <v-list-item-avatar
              size="32"
              class="mr-4 justify-center"
              color="grey lighten-2"
            >
              <span class="text-caption font-weight-medium"
                >{{ urun.miktar }}x</span
              >
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                <div class="text-body-2 font-weight-medium">
                  {{ sepetUrunBilgi(urun, "ad") }}
                </div>
                <div
                  v-if="sepetUrunBilgi(urun, 'aciklama')"
                  class="text-caption mt-n1"
                >
                  {{ sepetUrunBilgi(urun, "aciklama") }}
                </div>
              </v-list-item-title>

              <div class="d-flex align-center justify-space-between pt-1">
                <div class="d-flex align-center">
                  <v-chip outlined label small color="grey darken-3">
                    <span v-if="!urun.kisisellestirme">{{
                      sepetUrunBilgi(urun, "fiyat") | currency
                    }}</span>
                    <span v-else>{{
                      sepetUrunKisisellestirme(urun) | currency
                    }}</span>
                  </v-chip>
                </div>
                <div class="d-flex ">
                  <v-btn
                    icon
                    small
                    class="text-capitalize"
                    @click="cartUrunler[i].notDurum = !cartUrunler[i].notDurum"
                  >
                    <v-icon small>
                      {{
                        cartUrunler[i].notDurum
                          ? "mdi-pencil-off"
                          : "mdi-pencil"
                      }}
                    </v-icon>
                  </v-btn>
                  <v-btn icon small @click="arttir(i)">
                    <v-icon small>
                      mdi-plus-thick
                    </v-icon>
                  </v-btn>

                  <v-btn icon small elevation="0" @click="azalt(i)">
                    <v-icon v-if="urun.miktar > 1" small>
                      mdi-minus-thick
                    </v-icon>
                    <v-icon v-else small>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </div>
              </div>

              <v-expansion-panels
                v-if="urun.kisisellestirme.length > 0"
                class="pt-1"
                flat
              >
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="pa-0 text-caption  text-right"
                    color="grey lighten-5"
                    style="min-height:12px"
                  >
                    {{ $t("kisisellestirmeler") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="grey lighten-5">
                    <div
                      v-for="(kisisellestirme, ki) in urun.kisisellestirme"
                      :key="ki"
                    >
                      <v-chip
                        small
                        class="my-2 mr-2 "
                        :class="
                          kisisellestirmeBilgi(kisisellestirme, 'ilaveFiyat')
                            ? 'pr-0'
                            : ''
                        "
                      >
                        {{ kisisellestirmeBilgi(kisisellestirme, "ad") }}
                        <v-chip
                          v-if="
                            kisisellestirmeBilgi(kisisellestirme, 'ilaveFiyat')
                          "
                          small
                          class="ml-2 white--text"
                        >
                          +
                          {{
                            kisisellestirmeBilgi(kisisellestirme, "ilaveFiyat")
                              | currency
                          }}
                        </v-chip>
                      </v-chip>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <v-textarea
                v-model="urun.not"
                v-if="urun.notDurum"
                hide-details
                auto-grow
                rows="1"
                :label="$t('aciklama')"
                color="black"
                append-icon="mdi-comment"
                filled=""
                class="my-3"
                rounded
                dense
              />
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </div>
      </v-list>
      <v-card-actions class="pa-4">
        <v-spacer />
        <span class="text-caption pr-3 font-weight-medium text--disabled">
          {{ $t("sepetTutari") }}</span
        >

        <cart-toplam />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import store from "./../store/index";
export default {
  name: "Cart",
  data: () => ({}),
  computed: {
    ...get("cart", ["cartUrunler"]),
    ...get("shop", ["urunler", "kisisellestirmeler"]),
    menuYukseklikHesaplama() {
      return window.innerHeight / 1.75;
    },
    cartMiktar() {
      return _.sumBy(this.cartUrunler, "miktar");
    }
  },
  methods: {
    sepetGoruntule() {
      this.$store.set("app/alisverisTamamla", true);
      this.$store.set("app/sepetGoruntuleme", true);
    },
    azalt(val) {
      this.$store.set("cart/azaltUrun!", val).then();
    },
    arttir(val) {
      this.$store.set("cart/UrunlerArttir", val);
    },

    sepetUrunBilgi(val, yer) {
      var urun = _.find(this.urunler[0], {
        object_key: val.id
      });
      return urun[yer];
    },
    sepetUrunKisisellestirme(val) {
      var fiyat = _.find(this.urunler[0], {
        object_key: val.id
      }).fiyat;
      var ogeler = val.kisisellestirme;
      var fiyatlar = [];

      _.forEach(ogeler, oge => {
        let aranan;
        let durdur = true;

        if (_.isObject(oge)) {
          if (oge.object_key) {
            aranan = oge.object_key;
          } else {
            durdur = false;
          }
        } else {
          aranan = oge;
        }

        if (durdur) {
          fiyatlar.push(
            _.find(this.kisisellestirmeler[0], {
              object_key: aranan
            }).ilaveFiyat
          );
        } else {
          fiyatlar.push(oge.ilaveFiyat);
        }
      });

      var toplamFiyat = _.reduce(
        fiyatlar,
        function(sum, n) {
          return sum + n;
        },
        fiyat
      );
      return toplamFiyat;
    },
    kisisellestirmeBilgi(val, yer) {
      let aranan;
      let durdur = true;
      let kisisellestirme;
      if (_.isObject(val)) {
        if (val.object_key) {
          aranan = val.object_key;
        } else {
          durdur = false;
        }
      } else {
        aranan = val;
      }
      if (durdur) {
        kisisellestirme = _.find(this.kisisellestirmeler[0], {
          object_key: aranan
        });
      } else {
        kisisellestirme = val;
      }

      return kisisellestirme[yer];
    }
  }
};
</script>
<style lang="scss" scoped>
.arkaCik {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 60px;
    transition: all 0.33s;
    background-color: #ba6a20;
  }
  &:hover {
    &:before {
      width: 9px;
      opacity: 1 !important;
    }
  }
}
.v-list.overflow-y-auto {
  &::-webkit-scrollbar {
    background: #fff;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 20px;
  }
}
.v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    padding: 0 12px 12px;
  }
}
</style>
