<template>
  <div>
    <h1
      :class="
        mobil
          ? 'white--text text-body-2 font-weight-bold'
          : 'baslik-yazi-renk--text text-h6 text-md-h3 text-xl-h2 '
      "
      style="text-shadow: 1px 1px 1px hsl(0deg 0% 0% / 49%);"
    >
      {{ profil.sirket }}
    </h1>
    <h3 v-if="!mobil" class="text-caption text-sm-body-1 white--text mt-2">
      {{ profil.kisaTanim }}
    </h3>
  </div>
</template>

<script>
import { veri } from "./../db";
export default {
  name: "Titles",
  props: ["mobil"],
  data: () => ({
    profil: {}
  }),
  firebase: {
    profil: veri.ref("profil")
  }
};
</script>

<style lang="scss">
.v-application {
  .ufak {
    h1 {
      font-size: 21px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
    }
    h3 {
      font-size: 13px !important;
      line-height: 24px !important;
      font-weight: 300 !important;
    }
  }
}
</style>
