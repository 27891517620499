<template>
  <v-row align="center" class="yukseklik-ayarla hidden-sm-and-down">
    <v-col cols="3">
      <logo />
    </v-col>
    <v-col cols="9">
      <div>
        <!-- Üst Satır Başlangıç-->
        <div class="d-flex align-center justify-space-between">
          <titles />
        </div>
        <!-- Üst Satır Bitiş -->
        <!-- Alt Satır Başlangıç -->
        <div
          v-if="ayarlar.alisverisDurum"
          class="d-flex align-center justify-space-between pt-3 pt-lg-9"
        >
          <div class="d-flex align-center">
            <v-chip
              v-if="!musteriDurum"
              outlined
              close
              close-icon="mdi-refresh"
              color="info"
              text-color="info"
              class="pa-1 pr-3"
              link
              @click="bolgeDurum()"
              @click:close="bolgeDurum()"
            >
              <v-chip
                small
                link
                :color="renk"
                class="mr-2 pa-2 pr-3"
                @click="bolgeDurum()"
              >
                <v-icon small class="pr-0 pr-lg-2">
                  mdi-map-marker
                </v-icon>
                <span class="hidden-md-and-down">{{ $t("teslimat") }}</span>
              </v-chip>
              {{ bolgeGetir(bolge) }}
            </v-chip>
            <div v-else>
              <musteriBilgi />
            </div>

            <v-divider class="mx-md-6 mx-lg-9 mx-xl-12" dark vertical />
            <div class="text-left">
              <v-icon color="white" x-large>
                mdi-moped
              </v-icon>
              <div class="white--text text-caption py-2">
                {{ $t("teslimatUcret") }}
              </div>
              <v-chip label small color="info" text-color="black">
                {{ getirUcret | currency }}
              </v-chip>
            </div>
            <v-divider class="mx-md-6 mx-lg-9 mx-xl-12" dark vertical />
            <div class="text-left">
              <v-icon color="white" x-large>
                mdi-cart-outline
              </v-icon>
              <div class="white--text text-caption py-2">
                {{ $t("minSiparis") }}
              </div>
              <v-chip label small color="info" text-color="black">
                {{ enAzSipasir | currency }}
              </v-chip>
            </div>
          </div>

          <div class="">
            <div class="text-right pt-1 ">
              <div class="d-flex">
                <v-btn
                  depressed=""
                  color="tertiary font-weight-bold"
                  class="px-0 pr-3 mr-3 text-capitalize"
                  @click="sorgulama"
                >
                  <v-icon class="px-2" size="18">
                    mdi-target
                  </v-icon>
                  {{ $t("siparisSorgulama") }}
                </v-btn>
                <bestellannahme :mobil="false" />
              </div>
              <div class="text-caption mt-1">
                {{ zamanalamaDurum }}
              </div>
            </div>
          </div>
        </div>
        <!-- Alt Satır Bitiş -->
      </div>
      <!-- Meta Bitiş-->
    </v-col>
    <sorgu />
  </v-row>
</template>

<script>
import { veri, fonksiyon } from "../db";
import { get, sync } from "vuex-pathify";
import { O2A, FirstO2A } from "object-to-array-convert";
export default {
  name: "HeaderMasaustu",
  data: () => ({
    overlay: true,
    renk: "success",
    beklemeDurum: 3,
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  firebase: {
    ayarlar: veri.ref("ayarlar")
  },
  computed: {
    ...get("app", [
      "getirUcret",
      "enAzSipasir",
      "bolge",
      "teslimatTip",
      "bolgeler",
      "musteriDurum",
      "siparisDurum",
      "musteri",
      "sorguDurum",
      "zamanalamaDurum"
    ]),
    beklemeIcon() {
      let deger;
      if (this.beklemeDurum == 0) {
        deger = "mdi-timer-sand";
      } else if (this.beklemeDurum == 1) {
        deger = "mdi-fire";
      } else if (this.beklemeDurum == 2) {
        deger = "mdi-moped";
      }
      return deger;
    },
    beklemeRenk() {
      let deger;
      if (this.beklemeDurum == 0) {
        deger = "error";
      } else if (this.beklemeDurum == 1) {
        deger = "warning";
      } else if (this.beklemeDurum == 2) {
        deger = "success";
      }
      return deger;
    },
    beklemeYazi() {
      return this.siparisDurum[this.beklemeDurum];
    }
  },
  mounted() {
    if (this.musteriDurum) {
      this.bekleyenSiparis();
    }
  },
  methods: {
    cikisYap() {
      confirm(this.$t("cikisOnay")) &&
        (this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie)) ||
          location.reload());
    },

    bolgeGetir(val = false) {
      let deger;
      if (!_.isString(val)) {
        deger = this.$t("mekandaTeslim2");
        this.renk = "secondary";
      } else {
        deger = _.find(this.bolgeler[0], { object_key: val }).ad;
        this.renk = "primary";
      }
      return deger;
    },
    bolgeDurum() {
      this.$store.set("app/bolgelerDurum", true);
    },
    bekleyenSiparis() {
      let durum;
      veri
        .ref("siparisler")
        .orderByChild("musteriID")
        .equalTo(this.musteri.object_key)
        .on("value", event => {
          if (event.exists()) {
            const sorgu = _.filter(O2A(event), o => {
              return o.durum < 3;
            });
            this.beklemeDurum = sorgu.length > 0 ? sorgu[0].durum : 4;
          } else {
          }
        });
    },
    sorgulama() {
      this.$store.set("app/sorguDurum", true);
    }
  }
};
</script>
<style lang="scss">
.yukseklik-ayarla {
  height: 400px;
  margin-top: 6vh;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .yukseklik-ayarla {
    height: 300px;
    margin-top: 5vh;
  }
}
</style>
