<template>
  <div>
    <div class="text-right">
      <v-btn
        v-if="!mobil"
        depressed
        :color="renklendirme"
        class="text-capitalize"
        style="min-width: auto; padding:0 15px;"
        @click="dialog = true"
      >
        <v-icon class="">
          mdi-clock
        </v-icon>
      </v-btn>
      <v-btn
        v-if="mobil"
        :color="renklendirme"
        block
        depressed
        tile
        class="text-capitalize font-weight-bold "
        @click="dialog = true"
      >
        <v-icon size="16" left>
          mdi-clock
        </v-icon>
        {{ $t("calismaSaatleri") }}
      </v-btn>
    </div>

    <v-dialog
      v-model="dialog"
      :transition="
        $vuetify.breakpoint.mdAndDown
          ? 'slide-y-reverse-transition'
          : 'fade-transition'
      "
      scrollable
      max-width="400"
    >
      <v-card class="white">
        <v-card-title>
          {{ $t("calismaSaatleri") }}
          <v-spacer />
          <v-btn fab small elevation="0" @click="dialog = false">
            <v-icon size="21">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-0 pb-6 pt-6">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(zaman, i) in zamanlama[0]" :key="i">
                  <td class="pl-6 pr-0 pr-md-16">{{ zaman.gun }}</td>
                  <td v-if="zaman.tip == 1" class="px-0 pl-4 pr-1">
                    <v-chip
                      class="chip-block white--text"
                      label
                      color="success"
                    >
                      {{ zaman.acilis }}
                    </v-chip>
                  </td>
                  <td v-if="zaman.tip == 1" class="px-0 pl-1 pr-6">
                    <v-chip
                      class="chip-block white--text"
                      label
                      color="success"
                    >
                      {{ zaman.kapanis }}
                    </v-chip>
                  </td>
                  <td v-if="zaman.tip == 2" colspan="2" class="pr-6">
                    <v-chip class="chip-block" label color="success">
                      {{ $t("24Yazi2") }}
                    </v-chip>
                  </td>
                  <td v-if="zaman.tip == 3" colspan="2" class="pr-6">
                    <v-chip class="chip-block" label color="error">
                      {{ $t("kapaliYazi2") }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
export default {
  name: "Bestellannahme",
  props: {
    size: Boolean,
    mobil: Boolean
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...get("shop", ["zamanlama"]),
    ...get("app", ["magazaDurum", "zamanalamaDurum"]),
    renklendirme() {
      let deger;
      if (this.magazaDurum) {
        deger = "secondary";
      } else {
        deger = "error";
      }
      return deger;
    }
  }
};
</script>

<style lang="css" scoped></style>
