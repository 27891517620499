<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
// Styles
import "@/styles/overrides.sass";
import { veri } from "./db";
export default {
  name: "App",
  data: () => ({
    profil: {
      title: ""
    }
  }),
  metaInfo() {
    return {
      title: this.profil.title,
      titleTemplate: "%s | beefreund",
      htmlAttrs: { lang: "de" },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  },

  firebase: {
    profil: veri.ref("profil")
  }
};
</script>
