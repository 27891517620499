<template>
  <div class="rounded-lg primary">
    <v-navigation-drawer
      width="100%"
      class="py-2 px-2"
      color="transparent"
      :style="menuYukseklikHesaplama()"
    >
      <v-list
        nav
        :dense="profil.menuDense"
        v-bind="$attrs"
        dark=""
        v-on="$listeners"
      >
        <v-list-item-group v-model="menuDurum" mandatory>
          <template v-for="(kategori, i) in kategoriler[0]">
            <v-list-item
              :key="`item-${i}`"
              active-class="liste-aktif"
              v-bind="$attrs"
              @click="scrollTo(i)"
              v-on="$listeners"
            >
              <v-list-item-content>
                <v-list-item-title v-text="kategori.ad" />
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-divider dark />
    <div class="py-4">
      <v-switch
        v-model="show"
        inset
        hide-details=""
        class="navigationSwitch pt-0 mt-0"
        color="white"
        @change="kategoriDurum()"
      >
        <template slot="label">
          <div class="text-body-2 white--text">
            {{ $t("kategoriGoster") }}
          </div>
        </template>
      </v-switch>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import store from "./../store/index";
import { veri } from "./../db";

gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "Kategoriler",
  data: () => ({
    seciliKategori: 0,
    dense: true,
    profil: {
      menuDense: false
    }
  }),

  computed: {
    ...sync("app", ["show", "menuDurum"]),
    menuler: get("shop/menuler"),
    kategoriler: get("shop/kategoriler")
  },
  firebase: {
    profil: veri.ref("profil")
  },

  methods: {
    kategoriDurum() {
      for (var i = 0; i < this.menuler.length; i++) {
        store.set(
          "shop/kategoriler@0:" + i + ":show",
          (this.kategoriler[0][i].show = this.show)
        );
      }

      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    },
    menuYukseklikHesaplama() {
      if (document.getElementById("gizliBaslik")) {
        const hesap =
          window.innerHeight -
          document.getElementById("gizliBaslik").clientHeight +
          24 -
          150;

        hesap < 400 ? (this.dense = true) : (this.dense = false);

        return "max-height: " + hesap + "px !important";
      } else {
        setTimeout(() => {
          this.menuYukseklikHesaplama();
        }, 300);
      }
    },
    scrollTo(val) {
      var testDiv = document.getElementById("menu-" + val);
      gsap.to(window, 1, {
        scrollTo: {
          y: testDiv.offsetTop - 20,
          autoKill: true
        }
      });
    }
  }
};
</script>

<style lang="scss">
.v-navigation-drawer__content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
  &::-webkit-scrollbar {
    background: rgba(255, 255, 255, 0.5);
    width: 4px;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(25, 25, 25, 1);
    border-radius: 20px;
  }
}
.navigationSwitch {
  .v-input__slot {
    flex-direction: row-reverse;
  }
  .v-label {
    justify-content: flex-end;
    padding-right: 15px;
  }
}
</style>
