<template>
  <div>
    <v-card
      v-for="(menu, ui) in menuler"
      v-if="menu.durum"
      :id="`menu-${ui}`"
      :key="ui"
      color="primary"
      :elevation="$vuetify.breakpoint.mdAndDown ? 0 : 20"
      class="menuUrun  mb-0 mb-md-16"
      :class="$vuetify.breakpoint.mdAndDown ? 'rounded-0 ' : 'rounded-lg'"
      :data-konum="ui"
    >
      <v-img
        dark
        :height="menu.resim ? ($vuetify.breakpoint.mdAndDown ? 250 : 400) : 250"
        :src="menu.resim"
        style="cursor:pointer"
        gradient="to top, rgba(0,0,0,.25), rgba(0,0,0,0)"
        @click="gosterGizle(ui)"
      >
        <v-row align="end" justify="center" style="height:100%" no-gutters>
          <v-col cols="12">
            <div :class="`${menu.show ? 'pb-9' : 'pb-6'} px-3`">
              <div class="text-left ">
                <h1 class="text-h5 text-md-h3 ">
                  {{ menu.ad }}
                </h1>
              </div>
              <h4 v-if="menu.aciklama" class="text-caption text-md-body-1">
                {{ menu.aciklama }}
              </h4>
              <v-btn class="showBtn" icon color="secondary-darken-1">
                <v-icon x-large class="white--text">
                  {{ menu.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-img>
      <v-list
        v-if="menu.show"
        class=" mt-n5 position-relative pa-0"
        :class="$vuetify.breakpoint.mdAndDown ? 'rounded-0' : 'rounded-lg'"
        color="urun-arka-plan"
        dark
      >
        <div v-for="(item, index) in menu.items" v-if="item.durum" :key="index">
          <v-list-item class="pa-0 " :style="item.stok ? '' : 'opacity:0.25'">
            <v-list-item-content class="pa-0 " style="cursor: default;">
              <v-card elevation="0" color="transparent">
                <v-card-text class="px-3">
                  <v-list-item class="pa-0 align-start">
                    <v-avatar
                      v-if="item.file"
                      tile
                      :size="$vuetify.breakpoint.mdAndDown ? 90 : 100"
                      class="mr-3 mr-md-5 rounded-lg"
                      @click="dialogResimGoster(item.file)"
                    >
                      <v-img alt="Avatar" :src="item.file">
                        <v-btn icon>
                          <v-icon>
                            mdi-magnify-plus-outline
                          </v-icon>
                        </v-btn>
                      </v-img>
                    </v-avatar>

                    <v-list-item-content
                      class="align-self-center pa-0"
                      :class="item.file ? '' : 'ml-1'"
                      @click="item.show = !item.show"
                    >
                      <v-list-item-title
                        class="text-body-1 text-md-h6 font-weight-medium urun-baslik-renk--text text-normal-wrap mb-0 pb-0"
                      >
                        {{ item.ad }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-if="item.aciklama"
                        class="urun-aciklama-renk--text text-normal-wrap text-body-2 mt-n1 mb-1"
                      >
                        {{ item.aciklama }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="ayarlar.alisverisDurum"
                        class="d-flex align-center justify-space-between mt-1"
                      >
                        <div v-if="item.indirimDurum">
                          <v-chip small label color="success px-2">
                            {{ item.fiyat | currency }}
                          </v-chip>
                          <div class="d-flex align-center text-caption pt-1">
                            <div
                              class="text--disabled pr-1 text-decoration-line-through "
                            >
                              {{ item.indirimsizFiyat | currency }}
                            </div>
                            <div class="text--disabled pl-1">
                              {{
                                $t("indirimOran", { oran: item.indirimOran })
                              }}
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <v-chip small label color="primary px-2">
                            {{ item.fiyat | currency }}
                          </v-chip>
                        </div>

                        <div class="hidden-md-and-up">
                          <v-btn
                            v-if="
                              item.bilgilendirmeler &&
                                ayarlar.alisverisDurum &&
                                item.stok
                            "
                            small
                            icon
                            color="primary"
                            class="mr-2"
                            @click="bilgilendirmeGoster(item)"
                          >
                            <v-icon size="24">
                              mdi-information
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="ayarlar.alisverisDurum && item.stok"
                            fab
                            outlined
                            color="primary"
                            elevation="0"
                            :loading="item.loading"
                            @click="sepeteAt(item)"
                          >
                            <v-icon size="19">
                              mdi-cart-plus
                            </v-icon>
                          </v-btn>
                          <v-icon
                            color="red"
                            v-if="!item.stok"
                            class="mr-2"
                            size="21"
                          >
                            mdi-cart-off
                          </v-icon>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="hidden-sm-and-down pr-1">
                      <div class="d-flex align-center">
                        <v-btn
                          v-if="
                            item.bilgilendirmeler &&
                              ayarlar.alisverisDurum &&
                              item.stok
                          "
                          small
                          icon
                          color="primary"
                          class="mr-2"
                          @click="bilgilendirmeGoster(item)"
                        >
                          <v-icon size="24">
                            mdi-information
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="ayarlar.alisverisDurum && item.stok"
                          icon
                          outlined
                          color="primary"
                          fab
                          :loading="item.loading"
                          elevation="0"
                          @click="sepeteAt(item)"
                        >
                          <v-icon size="21">
                            mdi-cart-plus
                          </v-icon>
                        </v-btn>
                        <v-icon
                          color="red"
                          v-if="!item.stok"
                          class="mr-5"
                          size="21"
                        >
                          mdi-cart-off
                        </v-icon>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="menu.items.length != index + 1"
            class="urun-ayirici"
          />
        </div>
      </v-list>
    </v-card>
    <v-dialog v-model="dialogResim" width="500">
      <v-card>
        <v-card-text class="pa-0 ma-0">
          <v-img :src="buyukResim">
            <div class="d-flex pa-3">
              <v-spacer />
              <v-btn color="white" fab small @click="dialogResim = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <bilgilendirme :urun-bilgi="urun" />
    <kisisellestirme v-if="kisisellestirmeDurum" :urun-bilgi="urun" />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import store from "./../store/index";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { veri } from "./../db";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "Urunler",
  data: () => ({
    buyukResim: "",
    urun: {},
    bos: [],
    dialogResim: false,
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  firebase: {
    ayarlar: veri.ref("ayarlar")
  },
  computed: {
    ...sync("app", ["show", "gsapDurum", "menuDurum", "bilgilendirmeDialog"]),
    ...sync("shop", [
      "kategoriler",
      "urunler",
      "kisisellestirmeler",
      "kisisellestirmeDurum"
    ]),
    menuler: get("shop/menuler"),
    cart: get("cart/cartUrunler")
  },
  watch: {
    gsapDurum: function(val) {
      if (val) {
        ScrollTrigger.matchMedia({
          // desktop
          "(min-width: 993px)": this.masaustuGorunum,
          // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
          // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
          // mobile
          "(max-width: 992px)": this.mobilGorunum,
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore.
          // all
          all: function() {}
        });
      }
    }
  },
  methods: {
    dialogResimGoster(val) {
      this.buyukResim = val;
      this.dialogResim = true;
    },
    masaustuGorunum() {
      ScrollTrigger.batch(".menuUrun", {
        start: "top center",
        end: "bottom center",
        markers: false,
        onEnter: (elements, triggers) => {
          store.set("app/menuDurum", Number(elements[0].dataset.konum));
        },
        onEnterBack: (elements, triggers) => {
          store.set("app/menuDurum", Number(elements[0].dataset.konum));
        },
        onLeave: (elements, triggers) => {},
        onLeaveBack: (elements, triggers) => {}
      });
    },
    mobilGorunum() {
      // console.log("mobilGorunum");
    },
    bilgilendirmeGoster(val) {
      this.urun = val;
      this.$store.dispatch("shop/bilgilendirmeler").then(() => {
        this.bilgilendirmeDialog = true;
      });
    },
    sepeteAt(val) {
      val.loading = true;
      if (_.isObject(val.kisisellestirmeler)) {
        this.urun = val;
        if (this.kisisellestirmeler.length > 0) {
          this.$store.set("shop/kisisellestirmeDurum", true);
          val.loading = false;
        } else {
          this.$store.dispatch("shop/kisisellestirmeler").then(() => {
            this.$store.set("shop/kisisellestirmeDurum", true);
            val.loading = false;
          });
        }
      } else {
        var urun = {
          id: val.object_key,
          urunAd: val.ad,
          urunResim: val.file || "",
          urunFiyt: val.fiyat,
          kisisellestirme: _.isObject(val.kisisellestirmeler),
          aciklama: "",
          notDurum: false,
          not: "",
          miktar: 1
        };
        this.$store.set("cart/ekleUrun!", urun).then((val.loading = false));
      }
      console.log(val.loading);
    },
    gosterGizle(val) {
      store.set(
        "shop/kategoriler@0:" + val + ":show",
        (this.kategoriler[0][val].show = !this.kategoriler[0][val].show)
      );
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>
.showBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.v-chip.v-size--default {
  height: 41px !important;
}
.v-parallax__content {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 15%,
    rgba(0, 0, 0, 0) 100%
  );
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
