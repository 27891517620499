// Pathify
import {
  make,
} from 'vuex-pathify'
import {
  veri,
} from './../../db'
import {
  O2A,
} from 'object-to-array-convert'
import router from './../../router'
var _ = require('lodash')
const state = {
  kategoriler: [],
  urunler: [],
  menuler: [],
  kisisellestirmeler: [],
  bilgilendirmeler: [],
  kisisellestirmeDurum: false,
  zamanlama: [],
  date: null,
  time: null,
}
const getters = {
  ...make.getters(state),

  menuler(state) {
    if (state.urunler[0] && state.kategoriler[0]) {
      return state.kategoriler[0].map(item => ({
        ad: item.ad,
        aciklama: item.aciklama,
        sira: item.sira,
        resim: item.file,
        show: item.show,
        durum: item.durum,
        items: _.filter(state.urunler[0], {
          kategori: item.object_key,
        }),
      }))
    }
  },

}
const mutations = {

  ...make.mutations(state),

  KATEGORILER(state, val) {
    state.kategoriler.push(val)
  },
  URUNLER(state, val) {
    state.urunler.push(val)
  },
  ZAMANLAMA(state, val) {
    state.zamanlama.push(val)
  },
  KISISELLESTIRMELER(state, val) {
    state.kisisellestirmeler.push(val)
  },
  BILGILENDIRMELER(state, val) {
    state.bilgilendirmeler.push(val)
  },

}
const actions = {
  ...make.actions(state),

  init({
    commit,
  }) {
    return new Promise((resolve, reject) => {
      veri.ref('kategoriler')
        .on('value', event => {
          if (event.exists()) {
            commit('KATEGORILER', _.orderBy(O2A(event), ['sira'], ['asc']))
            veri.ref('urunler')
              .on('value', event => {
                if (event.exists()) {
                  var urunler = _.filter(O2A(event), (urun) => {
                    // ürün indirim sorgulama
                    if (urun.indirimDurum) {
                      urun.indirimsizFiyat = urun.fiyat
                      urun.fiyat = urun.fiyat - (urun.fiyat * urun.indirimOran / 100)
                    }
                    urun.loading = false

                    return urun
                  })

                  commit('URUNLER', _.orderBy(urunler, ['sira'], ['asc']))
                  veri.ref('zamanlama')
                    .on('value', event => {
                      if (event.exists()) {
                        commit('ZAMANLAMA', O2A(event))
                        resolve()
                      } else {
                        router.push({
                          path: 'kurulum',
                        })
                      }
                    })
                } else {
                  router.push({
                    path: 'kurulum',
                  })
                }
              })
          } else {
            router.push({
              path: 'kurulum',
            })
          }
        })
    })
  },
  kisisellestirmeler({
    commit,
  }) {
    return new Promise((resolve, reject) => {
      veri.ref('kisisellestirmeler')
        .on('value', event => {
          if (event.exists()) {
            commit('KISISELLESTIRMELER', O2A(event))
            resolve()
          } else {
            resolve()
          }
        })
    })
  },
  bilgilendirmeler({
    commit,
  }) {
    return new Promise((resolve, reject) => {
      veri.ref('bilgilendirmeler')
        .on('value', event => {
          if (event.exists()) {
            commit('BILGILENDIRMELER', O2A(event))
            resolve()
          } else {
            resolve()
          }
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
