<template>
  <v-dialog
    v-model="kisisellestirmeDurum"
    transition="dialog-bottom-transition"
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
    scrollable
    max-width="450px"
  >
    <v-card tile>
      <v-card-title class="pa-4">
        <div class="d-flex align-center" style="max-width:80%">
          <div class="">
            <div class="">
              {{ urunBilgi.ad }}
            </div>
            <div class="text-caption">
              {{ urunBilgi.aciklama }}
            </div>
          </div>
        </div>

        <v-spacer />

        <v-btn fab small elevation="0" @click="kisisellestirmeDurum = false">
          <v-icon size="21">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-0 grey lighten-3" :style="`max-height: 100%`">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div
            v-for="(kisisellestirme, i) in urunBilgi.kisisellestirmeler"
            :key="`kisisellestirme-${i}`"
          >
            <div class="px-4 pt-3">
              <div>
                <div class="black--text text-overline ">
                  {{ kisisellestirme.ad }}
                </div>
                <div class="mt-n2" v-if="kisisellestirme.aciklama">
                  {{ kisisellestirme.aciklama }}
                </div>
              </div>
              <v-checkbox
                v-for="(oge, oi) in kisisellestirme.ogeler"
                v-if="kisisellestirme.tarz == 1"
                :key="`oge-${i}-${oi}`"
                v-model="secilenler[i]"
                dense
                :rules="
                  kisisellestirme.kural
                    ? [
                        v =>
                          ((v || '').length >= kisisellestirme.enAz &&
                            (v || '').length <= kisisellestirme.enCok) ||
                          $t('enAzEnCok', {
                            min: kisisellestirme.enAz,
                            max: kisisellestirme.enCok
                          })
                      ]
                    : []
                "
                class="my-1"
                :hide-details="kisisellestirme.ogeler.length > oi + 1"
                required
                :value="oge"
              >
                <template v-slot:label>
                  <div class="d-flex align-center">
                    <span class="text-body-2 black--text font-weight-bold">
                      {{
                        oge.object_key ? ogeGetir(oge.object_key, "ad") : oge.ad
                      }}
                    </span>

                    <span
                      class="text-caption pl-2 black--text"
                      v-if="
                        (oge.object_key
                          ? ogeGetir(oge.object_key, 'ilaveFiyat')
                          : oge.ilaveFiyat) == 0
                          ? false
                          : true
                      "
                    >
                      +
                      {{
                        oge.object_key
                          ? ogeGetir(oge.object_key, "ilaveFiyat")
                          : oge.ilaveFiyat | currency
                      }}
                    </span>
                  </div>
                </template>

                <template v-slot:message>
                  <v-chip
                    color="error"
                    class="text-caption my-3"
                    text-color="white"
                    style="position: fixed !important;top: 50% !important;left: 50% !important;transform: translate(-50%,-50%) !important;"
                  >
                    <v-icon left> mdi-alert-circle </v-icon>
                    {{
                      $t("enAzEnCok", {
                        min: kisisellestirme.enAz,
                        max: kisisellestirme.enCok
                      })
                    }}
                  </v-chip>
                </template>
              </v-checkbox>

              <v-select
                v-if="kisisellestirme.tarz == 2"
                v-model="secilenler[i]"
                :items="ogeGetirYalin(kisisellestirme.ogeler)"
                :label="kisisellestirme.ad"
                outlined=""
                class="ozelSelect"
                :multiple="kisisellestirme.multiselect"
                item-text="ad"
                return-object
                clearable
                :rules="
                  kisisellestirme.kural
                    ? [
                        v =>
                          ((v || '').length >= kisisellestirme.enAz &&
                            (v || '').length <= kisisellestirme.enCok) ||
                          $t('enAzEnCok', {
                            min: kisisellestirme.enAz,
                            max: kisisellestirme.enCok
                          })
                      ]
                    : []
                "
                :menu-props="{ overflowY: true }"
              >
                <template v-slot:message>
                  <v-chip
                    color="error"
                    class="text-caption my-3 ml-n3"
                    text-color="white"
                  >
                    <v-icon left> mdi-alert-circle </v-icon>
                    {{
                      $t("enAzEnCok", {
                        min: kisisellestirme.enAz,
                        max: kisisellestirme.enCok
                      })
                    }}
                  </v-chip>
                </template>

                <template v-slot:selection="{ item, index }">
                  <div v-if="kisisellestirme.multiselect">
                    <span v-if="index === 0" class="text-caption">
                      <v-chip class="primary" small
                        >{{ secilenler[i].length }} Adet</v-chip
                      >
                      Seçim Yaptınız
                    </span>
                  </div>
                  <div v-else class="black--text">
                    {{ secilenler[0].ad }}
                  </div>
                </template>

                <template v-slot:item="data">
                  <div class="d-flex align-center py-2">
                    <div class="">
                      {{
                        data.item.object_key
                          ? ogeGetir(data.item.object_key, "ad")
                          : data.item.ad
                      }}
                    </div>

                    <div
                      v-if="
                        (ogeGetir(data.item.object_key, 'ilaveFiyat') ||
                          data.item.ilaveFiyat) > 0
                      "
                      class="text-caption primary--text pl-1"
                    >
                      +{{
                        data.item.object_key
                          ? ogeGetir(data.item.object_key, "ilaveFiyat")
                          : data.item.ilaveFiyat | currency
                      }}
                    </div>
                  </div>
                </template>
              </v-select>

              <v-radio-group
                v-if="kisisellestirme.tarz == 3"
                v-model="secilenler[i]"
                mandatory
                hide-details=""
                class="ma-0 mb-5"
              >
                <v-radio
                  v-for="(oge, oi) in kisisellestirme.ogeler"
                  :key="oge.object_key"
                  :value="oge.object_key ? oge.object_key : oge"
                >
                  <template v-slot:label>
                    <div class="d-flex align-center">
                      <span class="text-body-2 black--text font-weight-bold">
                        {{
                          oge.object_key
                            ? ogeGetir(oge.object_key, "ad")
                            : oge.ad
                        }}
                      </span>

                      <span
                        class="text-caption pl-2 black--text"
                        v-if="
                          (oge.object_key
                            ? ogeGetir(oge.object_key, 'ilaveFiyat')
                            : oge.ilaveFiyat) == 0
                            ? false
                            : true
                        "
                      >
                        +
                        {{
                          oge.object_key
                            ? ogeGetir(oge.object_key, "ilaveFiyat")
                            : oge.ilaveFiyat | currency
                        }}
                      </span>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <v-divider />
          </div>
        </v-form>
        <!-- <v-textarea
          v-model="not"
          auto-grow
          :label="$t('aciklama')"
          rows="1"
          outlined
          append-icon="mdi-comment"
        /> -->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <div class="mx-3 text-right">
          <div class="text-caption">
            {{ $t("urunTutar", { adet: miktar }) }}
          </div>
          <div class="text-body-1 font-weight-bold">
            {{ hesapla | currency }}
          </div>
        </div>
        <v-btn
          v-if="miktar > 1"
          depressed
          style="min-width: 41px;"
          color="grey lighten-2"
          class="pa-0"
          @click="azalt()"
        >
          <v-icon>
            mdi-minus
          </v-icon>
        </v-btn>
        <v-btn
          depressed
          style="min-width: 41px;"
          color="grey lighten-2"
          @click="arttir()"
          class="pa-0"
        >
          <v-icon size="16">
            mdi-plus-thick
          </v-icon>
        </v-btn>
        <div class="d-flex align-center justify-space-between">
          <v-btn
            color="primary"
            class="white--text px-4  text-capitalize ml-3"
            depressed=""
            @click="sepeteAt()"
          >
            {{ $t("sepeteEkle") }}
            <v-icon class="ml-3">
              mdi-cart-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get, sync } from "vuex-pathify";
import store from "./../store/index";
export default {
  name: "Kisisellestirme",
  props: ["urunBilgi"],
  data: () => ({
    not: "",
    notDurum: false,
    miktar: 1,
    secilenler: [],
    valid: true
  }),
  created() {
    for (var i = 0; i < this.urunBilgi.kisisellestirmeler.length; i++) {
      this.secilenler.push([]);
    }
  },
  computed: {
    ...sync("shop", ["kisisellestirmeler", "kisisellestirmeDurum"]),
    yukseklikHesaplama() {
      return 500;
    },

    hesapla() {
      var fiyatlar = [];

      _.forEach(this.secilenler, secilen => {
        if (_.isString(secilen)) {
          fiyatlar.push(
            _.find(this.kisisellestirmeler[0], {
              object_key: secilen
            }).ilaveFiyat
          );
        }
        if (secilen.ad) {
          fiyatlar.push(secilen.ilaveFiyat);
        }
        if (secilen.length > 0 && _.isObject(secilen)) {
          _.forEach(secilen, oge => {
            if (oge.object_key) {
              fiyatlar.push(
                _.find(this.kisisellestirmeler[0], {
                  object_key: oge.object_key
                }).ilaveFiyat
              );
            } else {
              fiyatlar.push(oge.ilaveFiyat);
            }
          });
        }
        // if (secilen.ad) {
        //   fiyatlar.push(secilen.ilaveFiyat);
        // } else {
        //   _.forEach(secilen, oge => {
        //     if (oge.object_key) {
        //       fiyatlar.push(
        //         _.find(this.kisisellestirmeler[0], {
        //           object_key: oge.object_key
        //         }).ilaveFiyat
        //       );
        //     } else {
        //       fiyatlar.push(oge.ilaveFiyat);
        //     }
        //   });
        // }
      });
      var toplamFiyat = _.reduce(
        fiyatlar,
        function(sum, n) {
          return sum + n;
        },
        this.urunBilgi.fiyat
      );
      return toplamFiyat * this.miktar;
    }
  },
  watch: {
    not: function(val) {
      if (val == "") {
        this.notDurum = false;
      } else {
        this.notDurum = true;
      }
    },
    kisisellestirmeDurum: function(val) {
      if (!val) {
        this.miktar = 1;
        this.not = "";
        this.notDurum = false;
      }
    }
  },
  methods: {
    azalt() {
      this.miktar--;
    },
    arttir() {
      this.miktar++;
    },
    sepeteAt() {
      if (this.$refs.form.validate()) {
        var urun = {
          id: this.urunBilgi.object_key,
          urunAd: this.urunBilgi.ad,
          urunResim: this.urunBilgi.file || "",
          urunAciklama: this.urunBilgi.aciklama,
          urunFiyt: this.urunBilgi.fiyat,
          kisisellestirme: _.flattenDepth(this.secilenler),
          not: this.not,
          notDurum: this.notDurum,
          miktar: this.miktar
        };
        this.$store.set("cart/ekleUrun!", urun).then(() => {
          this.kisisellestirmeDurum = false;
        });
      }
    },

    ogeGetir(val, yer) {
      const deger = _.find(this.kisisellestirmeler[0], {
        object_key: val
      });
      if (deger) {
        return deger[yer];
      }
    },
    ogeGetirYalin(val) {
      const dizi = [];

      _.forEach(val, o => {
        if (o.object_key) {
          dizi.push(
            _.find(this.kisisellestirmeler[0], {
              object_key: o.object_key
            })
          );
        } else {
          dizi.push(o);
        }
      });
      return dizi;
    }
  }
};
</script>
<style lang="scss">
.ozelCard-1 {
  .v-responsive__content {
    display: flex;
    flex-direction: column;
  }
}
</style>
