<template>
  <div>
    <v-card
      v-if="yuzdeHesapla < 100 && teslimatTip == 1"
      class=" pt-5"
      color="transparent"
      elevation="0"
    >
      <v-list-item two-line>
        <v-list-item-content class="pt-4">
          <v-list-item-title class="text-h6 info--text">
            {{ $t("minSiparis") }}
          </v-list-item-title>

          <v-list-item-subtitle class="text-caption pt-1">
            <i18n path="gerkliOlan" tag="div">
              <template v-slot:miktar>
                <v-chip small label color="red" class="px-1 white--text">
                  {{ (enAzSipasir - cartToplam) | currency }}
                </v-chip>
              </template>
              <template v-slot:yaziGerkliMiktar>
                <span class="white--text">{{ $t("yaziGerkliMiktar") }}</span>
              </template>
            </i18n>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon class="ma-0">
          <v-progress-circular
            :rotate="-90"
            :size="75"
            :width="5"
            :value="yuzdeHesapla"
            color="info"
          >
            {{ enAzSipasir }} €
          </v-progress-circular>
        </v-list-item-icon>
      </v-list-item>
    </v-card>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import gsap from "gsap";
import store from "./../store/index";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "SagUst",
  data: () => ({}),
  computed: {
    ...get("cart", ["cartUrunler", "cartToplam"]),
    ...get("app", [
      "enAzSipasir",
      "teslimatTip",
      "sepetYukseklik",
      "alisverisDurum"
    ]),
    yuzdeHesapla() {
      var completed = this.cartToplam;
      var target = this.enAzSipasir;
      var progress = Math.ceil((completed / target) * 100);

      if (progress >= 100) {
        this.$store.set("app/sepetYukseklik", 0);
        this.$store.set("app/alisverisDurum", true);
        gsap.to(".sepetYer", {
          top: 20
        });
      } else if (progress >= 1) {
        this.$store.set("app/sepetYukseklik", 160);
        this.$store.set("app/alisverisDurum", false);

        gsap.to(".sepetYer", {
          top: 172
        });
      }
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
      return progress || 0;
    }
  }
};
</script>

<style lang="css" scoped></style>
