<template>
  <v-dialog
    v-model="bilgilendirmeDialog"
    :transition="
      $vuetify.breakpoint.mdAndDown
        ? 'slide-y-reverse-transition'
        : 'fade-transition'
    "
    max-width="400"
    scrollable
  >
    <template>
      <v-card class="transparent">
        <v-card-title class=" white--text primary">
          <v-icon left color="white">
            mdi-information
          </v-icon>
          {{ $t("bilgilendirme") }}
          <v-spacer />
          <v-btn icon @click="bilgilendirmeDialog = false">
            <v-icon color="white" class="">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="white pa-0">
          <div class="px-6 pb-3  primary">
            <div class="text-caption white--text">
              {{ $t("urun") }}
            </div>
            <div class="text-body-1 white--text">
              {{ urunBilgi.ad }}
            </div>
          </div>
          <v-divider />
          <v-list
            v-if="alerjikler.length > 0"
            flat
            dense
            class="px-6 mt-3 mb-6"
          >
            <v-subheader class="pa-0">
              <v-icon size="18" color="grey" left>
                mdi-allergy
              </v-icon>

              {{ $t("alerjikler") }}
            </v-subheader>
            <v-list-item
              v-for="(alerjik, i) in alerjikler"
              :key="alerjik.object_key"
              class="pa-0 align-center"
            >
              <v-list-item-icon class="ma-0">
                <v-icon size="21">
                  mdi-chevron-right
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-caption">
                  {{ alerjik.aciklama }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider
            v-if="alerjikler.length > 0 && katkilar.length > 0"
            class="my-3"
          />
          <v-list v-if="katkilar.length > 0" flat dense class="px-6 ">
            <v-subheader class="pa-0">
              <v-icon size="18" color="grey" left>
                mdi-checkbox-blank-circle
              </v-icon>
              {{ $t("malzemeKatki") }}
            </v-subheader>
            <v-list-item
              v-for="(katki, i) in katkilar"
              :key="katki.object_key"
              class="pa-0 align-center"
            >
              <v-list-item-icon class="ma-0">
                <v-icon size="21">
                  mdi-chevron-right
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pa-0">
                <v-list-item-title class="text-caption">
                  {{ katki.aciklama }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { get, sync } from "vuex-pathify";
import store from "./../store/index";

export default {
  name: "Bilgilendirme",
  props: ["urunBilgi"],
  computed: {
    ...sync("app", ["bilgilendirmeDialog"]),
    ...sync("shop", ["bilgilendirmeler"]),
    alerjikler: function() {
      var bilgilendirmeler = this.bilgilendirmeler[0];
      var alerjikler = _.filter(bilgilendirmeler, { durum: 1 });
      var alerjiklerBurasi = [];
      _.forEach(this.urunBilgi.bilgilendirmeler, bilgiler => {
        var deger = _.find(alerjikler, { object_key: bilgiler });
        if (deger) {
          alerjiklerBurasi.push(deger);
        }
      });

      return alerjiklerBurasi;
    },
    katkilar: function() {
      var bilgilendirmeler = this.bilgilendirmeler[0];
      var katkilar = _.filter(bilgilendirmeler, { durum: 2 });
      var katkilarBurasi = [];
      _.forEach(this.urunBilgi.bilgilendirmeler, bilgiler => {
        var deger = _.find(katkilar, { object_key: bilgiler });
        if (deger) {
          katkilarBurasi.push(deger);
        }
      });

      return katkilarBurasi;
    }
  },
  data() {
    return {};
  },

  methods: {}
};
</script>

<style lang="css" scoped></style>
