// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ripple from 'vuetify/lib/directives/ripple'


Vue.use(Vuetify, {
  directives: {
    ripple,
  },
})



export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 960,
  },
  icons: {
    values: {
      expand: 'mdi-menu-down',
    },
  },
  theme: {
    light: true,
  },
})
