<template>
  <div class="">
    <div v-if="ayarlar.alisverisDurum">
      <div class="sepetAlan">
        <div id="gizliSepet" class="gizliSepet py-6">
          <sag-ust />
        </div>
        <div class="sepetYer">
          <cart />
          <div class="d-flex align-center justify-space-between mt-6">
            <div v-show="cartUrunler.length > 0">
              <alisveris-tamamla />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-sheet rounded="lg" class="pt-10 pb-7 text-center sepetAlan" color="">
        <v-icon left size="120" color="grey lighten-2">
          mdi-close-circle
        </v-icon>
        <div class="">
          <v-chip small class="px-6 mt-9 text-caption" color="primary">
            {{ $t("sistemKapali") }}
          </v-chip>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import store from "./../store/index";
import ScrollTrigger from "gsap/ScrollTrigger";
import { veri } from "./../db";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "SagTaraf",
  data: () => ({
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  firebase: {
    profil: veri.ref("profil"),
    ayarlar: veri.ref("ayarlar")
  },
  watch: {
    ayarlar: {
      deep: true,
      handler(val) {
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 100);
      }
    },

    gsapDurum: function(val) {
      if (val) {
        ScrollTrigger.matchMedia({
          // desktop
          "(min-width: 960px)": this.masaustuGorunum,
          // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
          // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
          // mobile
          "(max-width: 959px)": this.mobilGorunum,
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore.
          // all
          all: function() {}
        });
      }
    }
  },
  computed: {
    ...get("app", [
      "gsapDurum",
      "enAzSipasir",
      "teslimatTip",
      "sepetYukseklik"
    ]),
    ...get("cart", ["cartUrunler"])
  },
  methods: {
    masaustuGorunum() {
      var gizliSepet = document.getElementById("gizliSepet");

      ScrollTrigger.create({
        trigger: ".menuler",
        markers: false,
        start: () => "top " + this.sepetYukseklik,
        end: "bottom bottom",
        pin: ".sepetYer",
        pinSpacing: false,
        scrub: 1
      });
      ScrollTrigger.create({
        trigger: ".menuler",
        start: "top 0",
        end: "bottom -100px",
        pin: ".gizliSepet",
        scrub: 1,
        pinSpacing: false,
        markers: false
      });
    },
    mobilGorunum() {
      // console.log("mobilGorunum");
    }
  }
};
</script>

<style lang="scss">
.sepetAlan {
  position: relative;
}

.gizliSepet {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.sepetYer {
  position: relative;
  z-index: 2;
}
</style>
