import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { rtdbPlugin } from 'vuefire'
import VueCookies from 'vue-cookies'
import '@mdi/font/css/materialdesignicons.css'
import '@mdi/light-font/css/materialdesignicons-light.css'
import VueDateFns from 'vue-date-fns'

import VueMoment from 'vue-moment'
import moment from 'moment-timezone' // 'fr'

import {
  sync,
} from 'vuex-router-sync'

import VueCurrencyFilter from 'vue-currency-filter'
import i18n from './i18n'
Vue.use(VueCookies)

Vue.$cookies.config(Infinity)

Vue.use(VueDateFns)

Vue.use(VueMoment, {
    moment,
})

moment.locale('de')
Vue.use(VueCurrencyFilter, [
 { // default name 'currency'
   symbol: '€',
   thousandsSeparator: '.',
   fractionCount: 2,
   fractionSeparator: ',',
   symbolPosition: 'back',
   symbolSpacing: true,
   avoidEmptyDecimals: undefined,
 },

])
Vue.config.productionTip = false
Vue.use(rtdbPlugin)
sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
