<template>
  <v-chip
    label
    color="grey darken-3"
    text-color="white"
    class="font-weight-medium"
  >
    {{ hesapla | currency }}
  </v-chip>
</template>

<script>
  import { get } from 'vuex-pathify'

  import store from './../store/index'

  export default {
    name: 'CartToplam',
    data: () => ({}),
    computed: {
      ...get('cart', ['cartUrunler']),
      ...get('shop', ['urunler', 'kisisellestirmeler']),
      hesapla () {
        const fiyatToplam = []
        _.forEach(this.cartUrunler, urun => {
          var fiyat = _.find(this.urunler[0], { object_key: urun.id }).fiyat

          var ogeler = urun.kisisellestirme
          var fiyatlar = []
          _.forEach(ogeler, oge => {
            let aranan
            let durdur = true
            if (_.isObject(oge)) {
              if (oge.object_key) {
                aranan = oge.object_key
              } else {
                durdur = false
              }
            } else {
              aranan = oge
            }

            if (durdur) {
              fiyatlar.push(
                _.find(this.kisisellestirmeler[0], {
                  object_key: aranan,
                }).ilaveFiyat,
              )
            } else {
              fiyatlar.push(oge.ilaveFiyat)
            }
          })
          var toplamFiyat = _.reduce(
            fiyatlar,
            function (sum, n) {
              return sum + n
            },
            fiyat,
          )
          fiyatToplam.push(toplamFiyat * urun.miktar)
        })

        var sonToplam = _.reduce(
          fiyatToplam,
          function (sum, n) {
            return sum + n
          },
          0,
        )
        this.$store.set('cart/cartToplam', sonToplam)
        return sonToplam
      },
    },
  }
</script>

<style lang="css" scoped></style>
