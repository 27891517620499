// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from '@/plugins/vuex-pathify'

// Modules
import * as modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  plugins: [
    pathify.plugin,
  ],
})

/**
 * Mağaza açıldığında firebase bağlan ve gerekli verileri yükle.
 * İşem sonunda loaded durumunu güncelle
 */
 // store.dispatch('app/init').then(() => {
 //   store.dispatch('shop/init').then(() => {
 //     store.set('app/loaded', true)
 //     store.set('app/gsapDurum', true)
 //   })
 // })

export default store

export const ROOT_DISPATCH = Object.freeze({
  root: true,
})
