// Pathify
import {
  make,
} from 'vuex-pathify'
import {
  veri,
} from './../../db'
import {
  O2A,
  FirstO2A,
} from 'object-to-array-convert'
import router from './../../router'
import i18n from './../../i18n';
// Data
const state = {
  loaded: false,
  drawer: null,
  drawerImage: true,
  mini: false,
  show: true,
  gsapDurum: false,
  menuDurum: 0,
  bolgelerDurum: false,
  sorguDurum: false,
  magazaDurum: true,
  alisverisDurum: false,
  zamanalamaDurum: '',
  bolgeler: [],
  bolge: {},
  getirUcret: 0,
  enAzSipasir: 0,
  teslimatTip: 1,
  sepetYukseklik: 160,
  musteri: '',
  musteriDurum: false,
  alisverisTamamla: false,
  alisverisTamam: false,
  alisverisTamamlaDurum: 1,
  sepetGoruntuleme: false,
  bilgilendirmeDialog: false,
  planlamaDurum: 1,
  renkler: {},
  siparisDurum: ['Beklemede', 'Görüntülendi', 'Hazırlanıyor', 'Teslimatta', 'Teslim Edildi', 'İptal Edildi'],
  teslimatDurumlar: [
    i18n.t('mekandaTeslim'),
    i18n.t('adresteTeslim'),
  ],
  odemeDurumlar: [
    i18n.t('kapidaNakit'),
    i18n.t('kapidaKrediKarti'),
    i18n.t('mekandaOdeme'),
    i18n.t('paypal'),
  ],
}

const mutations = {

  ...make.mutations(state),

  BOLGELER(state, val) {
    state.bolgeler.push(val)
  },
  MUSTERI(state, val) {
    if (val.durum) {
      state.musteri = val
    }
  },

}

const actions = {
  ...make.actions(state),

  init({
    commit,
  }, musteri) {
    return new Promise((resolve, reject) => {
      if (musteri.ID) {
        veri.ref('musteriler').orderByChild('kod').equalTo(musteri.ID).on('value', event => {
          if (event.exists() && FirstO2A(O2A(event)).durum) {
            commit('MUSTERI', FirstO2A(O2A(event)))

            veri.ref('bolgeler')
              .on('value', event => {
                commit('BOLGELER', _.orderBy(O2A(event), ['kodu'], ['asc']))
                resolve()
              })
          } else {
            resolve()
          }
        })
      }

      veri.ref('bolgeler')
        .on('value', event => {
          if (event.exists()) {
            commit('BOLGELER', _.orderBy(O2A(event), ['kodu'], ['asc']))
            resolve()
          } else {
            router.push({
              path: 'kurulum'
            })
          }
        })
    })
  },



}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
