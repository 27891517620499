<template>
  <div class="menuAlan">
    <div id="gizliBaslik" class="gizliBaslik py-6">
      <div class="d-flex justify-center align-center">
        <img v-if="profil.logoKategori" :src="profil.logoKategori" />
      </div>
    </div>
    <div class="menuYer">
      <Kategoriler />
    </div>
  </div>
</template>

<script>
import { veri } from "./../db";

import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import store from "./../store/index";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export default {
  name: "SolTaraf",
  data: () => ({
    profil: {}
  }),
  watch: {
    gsapDurum: function(val) {
      if (val) {
        ScrollTrigger.matchMedia({
          // desktop
          "(min-width: 960px)": this.masaustuGorunum,
          // setup animations and ScrollTriggers for screens over 800px wide (desktop) here...
          // ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
          // mobile
          "(max-width: 959px)": this.mobilGorunum,
          // The ScrollTriggers created inside these functions are segregated and get
          // reverted/killed when the media query doesn't match anymore.
          // all
          all: function() {}
        });
      }
    }
  },
  computed: {
    ...get("app", ["gsapDurum"])
  },
  firebase: {
    profil: veri.ref("profil")
  },
  methods: {
    masaustuGorunum() {
      var gizliBaslik = document.getElementById("gizliBaslik");

      ScrollTrigger.create({
        trigger: ".menuler",
        markers: false,
        start: "top " + 175,
        end: "bottom bottom",
        pin: ".menuYer",
        pinSpacing: false,
        markers: false,
        scrub: 1
      });
      ScrollTrigger.create({
        trigger: ".menuler",
        start: "top 0",
        end: "bottom -100px",
        pin: ".gizliBaslik",
        scrub: 1,
        pinSpacing: false,
        markers: false
      });
    },
    mobilGorunum() {}
  }
};
</script>

<style lang="scss">
.menuAlan {
  position: relative;
}
.gizliBaslik {
  position: absolute;
  width: 100%;
  z-index: 1;
}
.menuYer {
  position: relative;
  z-index: 2;
}
</style>
