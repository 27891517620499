<template>
  <v-row align="center" class="hidden-md-and-up">
    <v-app-bar fixed height="60" color="arkaplan">
      <div style="position:absolute;left:0px;top:0px">
        <img v-if="profil.logoMobil" :src="profil.logoMobil" />
      </div>

      <v-spacer />
      <musteriBilgi />
      <v-btn icon color="white" depressed @click="sheet = true">
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-bottom-sheet v-model="sheet">
      <v-container class="white pa-0">
        <v-row no-gutters="">
          <v-col cols="6">
            <bestellannahme :mobil="true" />
          </v-col>
          <v-col cols="6">
            <v-btn
              color="tertiary"
              block
              depressed
              tile
              class="text-capitalize font-weight-bold "
              @click="sorgulama"
            >
              <v-icon size="16" left>
                mdi-target
              </v-icon>
              {{ $t("siparisSorgulama") }}
            </v-btn>
          </v-col>
          <v-col cols="12" class="grey lighten-3" v-if="!musteriDurum">
            <v-divider class="my-2" dark />
          </v-col>

          <v-col cols="12" class="grey lighten-3" v-if="!musteriDurum">
            <div class="d-flex align-center">
              <div style="overflow-y:auto;min-width:100%">
                <div class="d-flex justify-space-between">
                  <div class="pl-3" />
                  <div class="text-left">
                    <v-icon large>
                      mdi-map-marker
                    </v-icon>
                    <div class=" text-caption " style="white-space: nowrap;">
                      {{ $t("teslimat") }}
                    </div>
                    <v-chip
                      v-if="!musteriDurum"
                      close
                      small
                      close-icon="mdi-refresh"
                      color="primary"
                      text-color="white"
                      style="white-space: nowrap;"
                      @click="bolgeDurum()"
                      @click:close="bolgeDurum()"
                    >
                      {{ bolgeGetir(bolge) }}
                    </v-chip>
                  </div>
                  <v-divider class="mx-6 mx-lg-12" vertical />
                  <div class="text-left">
                    <v-icon large color="">
                      mdi-cart-outline
                    </v-icon>
                    <div class=" text-caption" style="white-space: nowrap;">
                      {{ $t("minSiparis") }}
                    </div>
                    <v-chip small outlined color="" text-color="">
                      {{ enAzSipasir | currency }}
                    </v-chip>
                  </div>
                  <v-divider class="mx-6 mx-lg-12" vertical />
                  <div class="text-left">
                    <v-icon large color="">
                      mdi-moped
                    </v-icon>
                    <div class=" text-caption " style="white-space: nowrap;">
                      {{ $t("teslimatUcret") }}
                    </div>
                    <v-chip small outlined color="" text-color="">
                      {{ getirUcret | currency }}
                    </v-chip>
                  </div>
                  <div class="pr-3" />
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" v-if="!musteriDurum" class="grey lighten-3">
            <v-divider class="my-2" dark />
          </v-col>
        </v-row>
      </v-container>
      <v-list flat class="overflow-y-auto arkaSilik " style="max-height:50vh">
        <v-subheader>{{ $t("kategoriler") }}</v-subheader>
        <v-list-item-group v-model="menuDurum" color="primary">
          <v-list-item
            v-for="(kategori, i) in kategoriler[0]"
            :key="`item-${i}`"
            @click="scrollTo(i)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="kategori.ad"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-bottom-sheet>

    <sorgu />
  </v-row>
</template>

<script>
import { veri } from "../db";
import { O2A, FirstO2A } from "object-to-array-convert";
import { get, sync } from "vuex-pathify";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "HeaderMobil",
  data: () => ({
    overlay: true,
    teslimatStatus: false,
    renk: "success",
    beklemeDurum: 3,
    selectedItem: 1,
    items: [
      { text: "Real-Time", icon: "mdi-clock" },
      { text: "Audience", icon: "mdi-account" },
      { text: "Conversions", icon: "mdi-flag" }
    ],
    profil: {},
    sheet: false,
    ayarlar: {
      alisverisDurum: ""
    }
  }),
  firebase: {
    ayarlar: veri.ref("ayarlar"),
    profil: veri.ref("profil")
  },
  computed: {
    ...sync("app", [
      "getirUcret",
      "enAzSipasir",
      "bolge",
      "teslimatTip",
      "bolgeler",
      "musteriDurum",
      "siparisDurum",
      "musteri",
      "sorguDurum",
      "show",
      "menuDurum"
    ]),

    menuler: get("shop/menuler"),
    kategoriler: get("shop/kategoriler"),
    beklemeIcon() {
      let deger;
      if (this.beklemeDurum == 0) {
        deger = "mdi-timer-sand";
      } else if (this.beklemeDurum == 1) {
        deger = "mdi-fire";
      } else if (this.beklemeDurum == 2) {
        deger = "mdi-moped";
      }
      return deger;
    },
    beklemeRenk() {
      let deger;
      if (this.beklemeDurum == 0) {
        deger = "error";
      } else if (this.beklemeDurum == 1) {
        deger = "warning";
      } else if (this.beklemeDurum == 2) {
        deger = "success";
      }
      return deger;
    },
    beklemeYazi() {
      return this.siparisDurum[this.beklemeDurum];
    }
  },
  mounted() {
    if (this.musteriDurum) {
      this.bekleyenSiparis();
    }
  },
  methods: {
    cikisYap() {
      confirm(this.$t("cikisOnay")) &&
        (this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie)) ||
          location.reload());
    },
    scrollTo(val) {
      var testDiv = document.getElementById("menu-" + val);
      gsap.to(window, 0, {
        scrollTo: {
          y: testDiv.offsetTop - 60
        }
      });
      this.sheet = false;
    },
    bolgeGetir(val = false) {
      let deger;
      if (!_.isString(val)) {
        deger = this.$t("mekandaTeslim");
        this.renk = "error";
      } else {
        deger = _.find(this.bolgeler[0], {
          object_key: val
        }).ad;
        this.renk = "success";
      }
      return deger;
    },
    bolgeDurum() {
      this.$store.set("app/bolgelerDurum", true);
    },
    bekleyenSiparis() {
      let durum;
      veri
        .ref("siparisler")
        .orderByChild("musteriID")
        .equalTo(this.musteri.object_key)
        .on("value", event => {
          if (event.exists()) {
            const sorgu = _.filter(O2A(event), o => {
              return o.durum < 3;
            });
            this.beklemeDurum = sorgu.length > 0 ? sorgu[0].durum : 4;
          } else {
          }
        });
    },
    sorgulama() {
      this.$store.set("app/sorguDurum", true);
    }
  }
};
</script>

<style lang="scss">
.arkaSilik {
  position: relative;
  &:before {
    content: "";
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    width: 100%;
    height: 75px;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgb(185 185 185) -100%,
      rgba(0, 0, 0, 0) 50%
    );
  }
}
</style>
