<template>
  <v-dialog v-model="alisverisTamam" max-width="400" persistent="">
    <v-card color="success" dark>
      <v-card-title class=" white--text">
        <div class="">
          <div class="">
            {{ $t("siparisTamam1") }}
          </div>
          <div class="text-caption">
            {{ $t("siparisTamam2") }}
          </div>
        </div>
      </v-card-title>
      <v-progress-linear
        color="green lighten-4"
        indeterminate
        rounded
        height="3"
        class="mt-1"
      ></v-progress-linear>
      <v-card-actions class="white">
        <v-spacer />
        <v-btn text class="success--text" @click="alisverisTamamlandi()">
          <v-icon size="24" left>
            mdi-check-bold
          </v-icon>
          {{ $t("tamam") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { get, sync } from "vuex-pathify";
import store from "./../store/index";
export default {
  name: "Tamam",
  computed: {
    ...sync("app", ["alisverisTamam"])
  },
  data: () => ({}),
  methods: {
    alisverisTamamlandi() {
      location.reload();
    }
  }
};
</script>

<style lang="css" scoped></style>
