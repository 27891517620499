import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyAoXJ1aIg301mrEoILHY-OyPPKLJnCCK_Y",
  authDomain: "heisse-mahlzeit-beefreund.firebaseapp.com",
  databaseURL: "https://heisse-mahlzeit-beefreund-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "heisse-mahlzeit-beefreund",
  storageBucket: "heisse-mahlzeit-beefreund.appspot.com",
  messagingSenderId: "506482222637",
  appId: "1:506482222637:web:ce39201b1afb9345ac7aa4",
  measurementId: "G-7WMPY0GT0N"
};

const fb = firebase.initializeApp(firebaseConfig)
const veri = fb.database()
const fonksiyon = fb.functions()
const depo = fb.storage()

firebase.analytics();

export {
  fb,
  veri,
  fonksiyon,
  depo,
}
