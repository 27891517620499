/**
 * Pathify helpers example
 *
 * See comments for details
 */
import {
  make,
} from 'vuex-pathify'
import i18n from './../../i18n';
const state = {
  cartUrunler: [],
  cartToplam: 0,
}

const getters = {
  // creates redundant getters, for those who like them
  ...make.getters(state),

  // additional total function
  total (state) {
    return state.foo + state.bar + state.baz.value
  },

}

const mutations = {

  // creates SET_* functions
  ...make.mutations(state),

  UrunlerArttir (state, val) {
    state.cartUrunler[val].miktar++
  },
  UrunlerAzalt (state, val) {
    state.cartUrunler[val].miktar--
  },
  UrunlerEkle (state, val) {
    state.cartUrunler.push(val)
  },
  UrunlerSil (state, val) {
    state.cartUrunler.splice(val, 1)
  },
}

const actions = {

  // creates redundant actions, but for foo only
  ...make.actions('foo'),

  // additional loadFoo action
  ekleUrun ({
    commit,
    state,
  }, val) {
    return new Promise(function (resolve, reject) {
      var durum = _.findIndex(state.cartUrunler, function (o) {
        return o.id == val.id
      })

      if (durum > -1 && !val.kisisellestirme) {
        commit('UrunlerArttir', durum)
      } else {
        commit('UrunlerEkle', val)
      }

      resolve(val)
    })
  },
  azaltUrun ({
    commit,
    state,
  }, val) {
    return new Promise(function (resolve, reject) {
      if (state.cartUrunler[val].miktar > 1) {
        commit('UrunlerAzalt', val)
      } else {
        if (confirm(i18n.t('urunSilOnay'))) {
          commit('UrunlerSil', val)
        }
      }

      resolve(val)
    })
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
