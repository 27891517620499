<template>
  <v-dialog
    v-model="sorguDurum"
    :transition="
      $vuetify.breakpoint.mdAndDown
        ? 'slide-y-reverse-transition'
        : 'fade-transition'
    "
    max-width="400"
    scrollable
    :fullscreen="
      $vuetify.breakpoint.smAndDown && goster && durum ? true : false
    "
  >
    <template>
      <v-card tile class="white">
        <v-card-title class="pa-0 px-3 py-3">
          {{ $t("siparisSorgulama") }}
          <v-spacer />
          <v-btn fab small elevation="0" @click="sorguDurum = false">
            <v-icon size="21">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text v-if="!goster" class="pt-3">
          <v-text-field
            v-model="email"
            hide-details=""
            color="success"
            outlined=""
            :label="$t('epostaYaz')"
          />
        </v-card-text>
        <v-card-text v-if="goster && !durum" class=" pb-9">
          {{ $t("sorguHata") }}
        </v-card-text>
        <v-card-text v-if="goster && bilgi" class="pa-0 grey lighten-4">
          <v-divider />
          <v-expansion-panels tile flat accordion>
            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0 pl-3 pr-4">
                <v-row no-gutters class="align-center">
                  <v-col cols="12">
                    <span class="font-weight-medium">{{
                      $t("siparisiniz")
                    }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-divider />
              <v-expansion-panel-content class="pa-0">
                <v-list three-line class="py-0 grey lighten-4">
                  <template v-for="(item, index) in bilgi.gerceklesen">
                    <v-list-item :key="index">
                      <v-list-item-icon class="mr-6">
                        <v-icon color="">
                          mdi-label
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <div class="mt-1 mb-2">
                          <v-list-item-title
                            class="text-body-1 font-weight-medium"
                          >
                            {{ item.miktar }} x {{ item.ad }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="item.aciklama">
                            {{ item.aciklama }}
                          </v-list-item-subtitle>
                        </div>
                        <div class="mb-2" v-if="item.kisisellestirmeler">
                          <v-list-item-subtitle
                            v-for="(kisisellestirme,
                            ki) in item.kisisellestirmeler"
                            :key="ki"
                          >
                            <span>&mdash; {{ kisisellestirme.ad }}</span>

                            <span
                              v-if="kisisellestirme.ilaveFiyat"
                              class="font-weight-bold"
                            >
                              +
                              {{ kisisellestirme.ilaveFiyat | currency }}</span
                            >
                          </v-list-item-subtitle>
                        </div>

                        <v-list-item-subtitle class="mb-2" v-if="item.not">
                          {{ item.not }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="mb-2">
                          <v-chip class="primary" label small>
                            {{
                              toplamUrunFiyatHesaplama(
                                item.fiyat,
                                item.miktar,
                                item.kisisellestirmeler
                              ) | currency
                            }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider />
                  </template>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="pa-0 pl-3 pr-4">
                <v-row no-gutters class="align-center">
                  <v-col cols="12">
                    <span class="font-weight-medium">{{
                      $t("ayrintilar")
                    }}</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="">
                <v-divider />
                <v-list color="grey lighten-4">
                  <v-list-item three-line>
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-bank
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ $t("odeme") }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="py-1">
                        <v-chip color="primary" small label>
                          {{ bilgi.tutar | currency }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-caption">
                        {{ odemeDurumlar[bilgi.odemeTip - 1] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />

                  <v-list-item three-line>
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-moped
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        $t("teslimat")
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle class="py-1">
                        <v-chip color="primary" small label>
                          <div v-if="bilgi.planlama == 1">
                            {{ $t("enErkenTeslimat") }}
                          </div>
                          <div v-if="bilgi.planlama == 2">
                            {{ bilgi.date | moment("DD MMMM dddd") }}
                            {{ $t("saat") }}
                            {{ bilgi.time }}
                          </div>
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-title class="text-caption">
                        {{ teslimatDurumlar[bilgi.teslimatTip] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />

                  <v-list-item two-line v-if="bilgi.siparisNot">
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-comment-text-outline
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ $t("siparisNotu") }}
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ bilgi.siparisNot }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />

                  <v-list-item two-line>
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ $t("adSoyad") }}
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ kisiAd }} {{ kisiSoyad }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />

                  <v-list-item two-line>
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-phone
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        $t("telefon")
                      }}</v-list-item-subtitle>
                      <v-list-item-title>{{ tel }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />

                  <v-list-item two-line>
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-email
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        $t("eposta")
                      }}</v-list-item-subtitle>
                      <v-list-item-title>{{ mail }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="bilgi.teslimatTip > 0" />

                  <v-list-item two-line v-if="bilgi.teslimatTip > 0">
                    <v-list-item-icon class="mr-6">
                      <v-icon color="">
                        mdi-map-marker
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ adres }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ bolgeDeger.ad }},
                        {{ bolgeDeger.kodu }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-divider />
          <v-timeline align-top dense>
            <v-timeline-item color="success" small>
              <div class="d-flex w-100 justify-space-between pr-6">
                <div class="font-weight-medium text-body-1 black--text">
                  {{ $t("siparisZamani") }}
                </div>
              </div>
              <div class="">
                {{
                  [bilgi.tarih - Date.now(), "milliseconds"]
                    | duration("humanize", true)
                }}
                <!-- {{ bilgi.tarih | moment("Do MMMM , HH:mm") }} -->
              </div>
            </v-timeline-item>
            <v-timeline-item
              :color="bilgi.goruntulemeTarih ? 'success' : 'grey'"
              small
            >
              <div class="d-flex w-100 justify-space-between pr-6">
                <div
                  :class="
                    bilgi.goruntulemeTarih
                      ? 'font-weight-medium text-body-1 black--text'
                      : 'text-body-1'
                  "
                >
                  {{ $t("goruntulemeZamani") }}
                </div>
              </div>
              <div v-if="bilgi.goruntulemeTarih">
                {{
                  [bilgi.goruntulemeTarih - Date.now(), "milliseconds"]
                    | duration("humanize", true)
                }}
                <!-- {{ bilgi.hazirTarih | moment("Do MMMM , HH:mm") }} -->
              </div>
            </v-timeline-item>

            <v-timeline-item
              :color="bilgi.hazirTarih ? 'success' : 'grey'"
              small
            >
              <div class="d-flex w-100 justify-space-between pr-6">
                <div
                  :class="
                    bilgi.hazirTarih
                      ? 'font-weight-medium text-body-1 black--text'
                      : 'text-body-1'
                  "
                >
                  {{ $t("hazirlamaBaslangic") }}
                </div>
              </div>
              <div v-if="bilgi.hazirTarih">
                {{
                  [bilgi.hazirTarih - Date.now(), "milliseconds"]
                    | duration("humanize", true)
                }}
                <!-- {{ bilgi.hazirTarih | moment("Do MMMM , HH:mm") }} -->
              </div>
            </v-timeline-item>

            <v-timeline-item
              :color="bilgi.yoldaTarih ? 'success' : 'grey'"
              :small="bilgi.yoldaTarih ? false : true"
            >
              <div class="d-flex w-100 justify-space-between pr-6">
                <div
                  :class="
                    bilgi.yoldaTarih
                      ? 'font-weight-medium text-body-1 black--text'
                      : 'text-body-1'
                  "
                >
                  <span v-if="bilgi.teslimatTip == 1">
                    {{ $t("teslimatBaslama") }}
                  </span>
                  <span v-else>
                    {{ $t("teslimatMekanda") }}
                  </span>
                </div>
              </div>
              <div v-if="bilgi.yoldaTarih">
                {{
                  [bilgi.yoldaTarih - Date.now(), "milliseconds"]
                    | duration("humanize", true)
                }}
                <!-- {{ bilgi.yoldaTarih | moment("Do MMMM , HH:mm") }} -->
              </div>
            </v-timeline-item>
            <v-timeline-item v-if="bilgi.yoldaTarih" color="grey" small>
              <span v-if="bilgi.teslimatTip == 1">
                {{ $t("siparisKapida") }}
              </span>
              <span v-else>
                {{ $t("siparisMekanda") }}
              </span>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
        <v-card-actions v-if="!goster" class="success text-center">
          <v-btn
            v-if="!goster"
            text
            block
            class="white--text text-capitalize"
            @click="sorgulama"
          >
            {{ $t("sorgula") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { O2A, FirstO2A } from "object-to-array-convert";
import store from "./../store/index";
import { veri } from "./../db";

export default {
  name: "Sorgu",
  computed: {
    ...sync("app", [
      "sorguDurum",
      "teslimatDurumlar",
      "odemeDurumlar",
      "musteriDurum",
      "musteri",
      "planlamaDurum",

      "bolgeler"
    ]),
    ...get("shop", ["date", "time"]),
    bolgeDeger() {
      const veri = _.find(this.bolgeler[0], { object_key: this.bolge });
      return veri;
    }
  },
  data: () => ({
    email: "",
    adres: "",
    siparisNot: "",
    kisiAd: "",
    kisiSoyad: "",
    tel: "",
    mail: "",
    bolge: "",
    goster: false,
    durum: false,
    bilgi: null,
    reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  }),
  watch: {
    sorguDurum(val) {
      if (val == false) {
        this.email = "";
        this.goster = false;
        this.durum = false;
        this.bilgi = null;
      } else {
        if (this.$cookies.get("cerezMusteriBilgi")) {
          this.email = this.$cookies.get("cerezMusteriBilgi").mail
            ? this.$cookies.get("cerezMusteriBilgi").mail
            : "";

          this.sorgulama();
        }
        if (this.musteriDurum) {
          this.email = this.musteri.mail;

          this.sorgulama();
        }
      }
    }
  },
  methods: {
    toplamUrunFiyatHesaplama(fiyat, miktar, kisisellestirmeler) {
      return miktar * (fiyat + _.sumBy(kisisellestirmeler, "ilaveFiyat"));
    },
    isEmailValid: function() {
      return this.email == "" ? true : !this.reg.test(this.email);
    },
    sorgulama() {
      veri
        .ref("musteriler")
        .orderByChild("mail")
        .equalTo(this.email)
        .on("value", siparisEvent => {
          var musteriler = [];
          if (siparisEvent.exists()) {
            var siparisler = O2A(siparisEvent);
            this.adres = siparisler[0].adres || "";
            this.kisiAd = siparisler[0].ad;
            this.kisiSoyad = siparisler[0].soyad;
            this.tel = siparisler[0].tel;
            this.mail = siparisler[0].mail;
            this.bolge = siparisler[0].bolge || "";
            veri
              .ref("siparisler")
              .orderByChild("musteriID")
              .equalTo(siparisler[0].object_key)
              .on("value", event => {
                if (event.exists()) {
                  _.forEach(O2A(event), siparis => {
                    if (siparis.durum <= 3) {
                      this.durum = true;
                      this.bilgi = Object.assign({}, siparis);
                    }
                  });
                }
              });
          } else {
            // this.durum = false;
          }
        });
      this.goster = true;
    }
  }
};
</script>

<style lang="css" scoped>
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #66bd69;
}
</style>
<style lang="css">
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
