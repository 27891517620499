<template>
  <div class="text-right">
    <div class="white--text text-caption text-sm-body-1">
      Google Reviews
    </div>
    <v-rating
      :value="3.5"
      color="amber"
      background-color="info"
      dense
      half-increments
      readonly
      :size="$vuetify.breakpoint.smAndUp ? 24 : 18"
    />
    <div class="text-caption text-sm-overline">
      4.5 (413)
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Reviews',
  }
</script>

<style lang="css" scoped>
</style>
