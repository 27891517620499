<template>
  <div class="">
    <v-dialog
      ref="dialogTarih"
      v-model="tarihDurum"
      :return-value.sync="date"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="date"
          :label="$t('tarih')"
          prepend-icon="mdi-calendar"
          readonly
          filled
          background-color="grey lighten-2"
          rounded
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        :min="minTarih"
        :max="maxTarih"
        locale="de-de"
        :allowed-dates="tarihStep"
        scrollable
        color="primary"
        @change="tarihDegis()"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          class="text-capitalize"
          @click="tarihDurum = false"
        >
          {{ $t("vazgec") }}
        </v-btn>

        <v-btn
          depressed=""
          color="primary"
          class="text-capitalize"
          @click="$refs.dialogTarih.save(date)"
        >
          {{ $t("tamam") }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <v-dialog
      ref="dialogSaat"
      v-model="saatDurum"
      :return-value.sync="time"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="time"
          filled
          background-color="grey lighten-2"
          rounded
          :label="$t('saat')"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-time-picker
        v-model="time"
        :allowed-minutes="saatStep"
        full-width
        format="24hr"
        :min="minSaat"
        :max="maxSaat"
        color="primary"
        scrollable
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          class="text-capitalize"
          @click="saatDurum = false"
        >
          {{ $t("vazgec") }}
        </v-btn>
        <v-btn
          depressed=""
          class="text-capitalize"
          color="primary"
          @click="$refs.dialogSaat.save(time)"
        >
          {{ $t("tamam") }}
        </v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { O2A, FirstO2A } from "object-to-array-convert";
import store from "./../store/index";
import { veri } from "./../db";
import moment from "moment";
export default {
  name: "Planlama",
  computed: {
    ...sync("shop", ["zamanlama", "date", "time"]),
    ...get("app", ["magazaDurum"]),
    minTarih() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    maxTarih() {
      return moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD");
    }
  },
  data: () => ({
    tarihDurum: false,
    tarihSecim: false,
    saatDurum: false,
    minSaat: "01:00",
    maxSaat: "23:00",
    gunler: [],
    saatStep: m => m % 15 === 0
  }),
  mounted() {
    for (var i = 0; i < this.zamanlama[0].length; i++) {
      if (this.zamanlama[0][i].tip != 3) {
        this.gunler.push(i);
      }
    }
  },
  methods: {
    tarihDegis() {
      this.tarihSecim = true;
      const bugun = new Date().toISOString().substr(0, 10);
      const date = moment(this.date);
      const dow = date.day();
      if (this.date == bugun) {
        this.minSaat = moment(new Date())
          .add(30, "minutes")
          .format("HH:mm");
        this.maxSaat = this.zamanlama[0][dow].kapanis;
      } else {
        this.minSaat = this.zamanlama[0][dow].acilis;
        this.maxSaat = this.zamanlama[0][dow].kapanis;
      }
    },
    tarihStep(val) {
      const date = moment(val);
      const bugun = new Date().toISOString().substr(0, 10);
      const dow = date.day();
      let deger = false;
      if (_.includes(this.gunler, dow)) {
        deger = true;
        if (val == bugun && !this.magazaDurum) {
          var acilisTarih = moment(
            bugun + " " + this.zamanlama[0][dow].acilis,
            "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm");
          var simdiTarih = moment(new Date()).format("YYYY-MM-DD HH:mm");
          if (moment(simdiTarih).isBefore(acilisTarih)) {
            deger = true;
          } else {
            deger = false;
          }
        }
      }
      return deger;
    }
  }
};
</script>

<style lang="css" scoped></style>
