<template>
  <div class="logo text-center">
    <img v-if="profil.logo" :src="profil.logo" />
  </div>
</template>

<script>
import { veri } from "./../db";
export default {
  name: "Logo",
  props: ["mobil"],
  data: () => ({
    profil: {}
  }),
  firebase: {
    profil: veri.ref("profil")
  }
};
</script>

<style lang="scss">
.logo {
  img {
    width: 250px;
  }
  &.ufak {
    img {
      width: 200px;
    }
  }
}
.mobilLogo {
  width: 125px !important;
}
@media #{map-get($display-breakpoints, 'md-and-down')} {
  .logo {
    img {
      width: 175px;
    }
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .logo {
    img {
      width: 250px;
    }
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .logo {
    img {
      width: 250px;
    }
  }
}
</style>
