<template>
  <v-dialog v-if="musteriDurum" v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        rounded
        depressed
        class="text-capitalize px-0 pr-2 black--text justify-start"
        color="white"
        style="max-width: 150px; overflow: hidden; white-space: nowrap;text-overflow: ellipsis;"
      >
        <v-icon class="px-1" color="primary" size="16">
          mdi-account-circle
        </v-icon>
        {{ musteri.ad }} {{ musteri.soyad }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        {{ $t("bilgileriniz") }}
        <v-spacer />
        <v-btn fab small elevation="0" @click="dialog = false">
          <v-icon size="21">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-list two-line color="transparent">
          <v-list-item class="px-0">
            <v-list-item-icon>
              <v-icon color="grey">
                mdi-account
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t("adSoyad") }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ musteri.ad }}
                {{ musteri.soyad }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-0">
            <v-list-item-icon>
              <v-icon color="grey">
                mdi-phone
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle> {{ $t("telefon") }}</v-list-item-subtitle>
              <v-list-item-title>
                {{ musteri.tel }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-0">
            <v-list-item-icon>
              <v-icon color="grey">
                mdi-email
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>
                {{ $t("eposta") }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ musteri.mail }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />

          <v-list-item class="px-0">
            <v-list-item-icon>
              <v-icon color="grey">
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle> {{ $t("adres") }}</v-list-item-subtitle>
              <v-list-item-title>
                {{ musteri.adres }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="primary">
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          large
          class="text-capitalize"
          text
          @click="cikisYap()"
        >
          {{ $t("girisBilgi") }}<v-icon class="pl-3">mdi-logout</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { get } from "vuex-pathify";
export default {
  name: "musteriBilgi",
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...get("app", ["musteriDurum", "musteri"])
  },
  methods: {
    cikisYap() {
      confirm(this.$t("cikisOnay")) &&
        (this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie)) ||
          location.reload());
    }
  }
};
</script>
